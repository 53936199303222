import { useNavigate } from 'react-router-dom';
import { faLongArrowLeft, faLongArrowRight, faSync } from '@fortawesome/pro-regular-svg-icons';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import './VehicleTradeInCard.css';
import { FullBlockRoute } from '../../types/blocks';
import { TradeinBlock, TradeInResponseBlock } from '../../types/tradein-block';
import { LoginState } from '../authentication/LoginForm';
import { Selector } from '../selectors/Selector';
// import { SelectorText } from '../selectors/SelectorText';
import { LargeLabel } from '../text/LargeLabel';
import { Colors } from 'src/types/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHandleRedirect } from 'src/handlers/useHandleRedirect';
import { TradeInRoute } from 'src/constants/routes';
import { ComponentState } from 'src/types/component';
import { useConfig } from '../context/config';

interface VehicleTradeInCardProps {
  tradeIn: TradeinBlock | TradeInResponseBlock;
  handleDelete?: (id: string) => Promise<void>;
  readOnly?: boolean;
}

export default function VehicleTradeInCard({
  tradeIn,
  handleDelete,
  readOnly = false,
}: VehicleTradeInCardProps) {
  const navigate = useNavigate();
  const config = useConfig()!;
  const min = tradeIn.minTrade || null;
  const max = tradeIn.maxTrade || null;
  const average = tradeIn.averageTrade || null;
  const handleRedirect = useHandleRedirect();

  return (
    <Selector
      type={config.isRetailing ? 'multi' : 'button'}
      title={`${tradeIn.year} ${tradeIn.make} ${tradeIn.model}`}
      subtitle={tradeIn.trim}
      state={readOnly || tradeIn.ucgvehicleid === null ? [ComponentState.ReadOnly] : []}
      linkState={LoginState.Verify}
      to={config.isRetailing ? `${FullBlockRoute.TradeIn}${tradeIn.id}` : '/login'}
      handleClick={() => {
        if (config.isRetailing) return;
        handleRedirect(TradeInRoute);
      }}
      menuItems={
        config.isRetailing
          ? [
              {
                icon: faSync,
                text: 'Edit Vehicle',
                onClick: () => navigate(`${FullBlockRoute.TradeIn}${tradeIn.id}`),
              },
              {
                icon: faMinusCircle,
                text: 'Remove Vehicle',
                onClick: async () => {
                  handleDelete!(tradeIn.id);
                },
                confirmModal: {
                  title: 'Are you sure?',
                  body: 'Removing this vehicle may also delete some information attached to this deal. This will also remove any existing offers attached to this deal.',
                },
              },
            ]
          : null
      }
    >
      <div className="valuation-card--features">
        {min ? (
          <LargeLabel color={Colors.Red1} text={`$${Number(min).toLocaleString('en-US')}`} />
        ) : (
          <span></span>
        )}

        <FontAwesomeIcon icon={faLongArrowLeft} className="valuation-card--icon" />
        {average ? (
          <LargeLabel
            color={Colors.Blue1}
            text={`${Number(average).toLocaleString('en-US')}`}
            className="mod-primary"
          />
        ) : null}
        <FontAwesomeIcon icon={faLongArrowRight} className="valuation-card--icon" />
        {max ? (
          <LargeLabel color={Colors.Green1} text={`$${Number(max).toLocaleString('en-US')}`} />
        ) : (
          <span></span>
        )}
      </div>
    </Selector>
  );
}
