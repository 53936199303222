import { MatrixItem, MatrixLoadingItem } from './MatrixItem';
import './Matrix.css';
import { useMemo } from 'react';
import { useDeals } from '../context/DealsProvider';
import { useDesk, useProgramSummary } from 'src/fetches/useSWRFetch';
import { Callout } from '../form/Callout';
import { useFormSubmit } from '../form/Form';
import { UseFormReturn } from 'react-hook-form';
import { MatrixContactItem } from './MatrixContactItem';

interface MatrixProps {
  form: UseFormReturn<any>;
}

export function Matrix({ form }: MatrixProps) {
  const { isSubmitting } = useFormSubmit();
  const { activeDealId, deal, dealIsLoading } = useDeals();
  const { desk, deskIsLoading } = useDesk(activeDealId, 'desk', deal.vin);
  const { programSummaryIsLoading } = useProgramSummary(activeDealId, !desk);

  const formPaymentMethod = form.watch('paymentMethod');

  const renderClass = useMemo(() => {
    const classes = ['matrix'];

    if (deal?.isDeskingLocked) classes.push('is-locked');

    return classes.join(' ');
  }, [deal]);

  if (desk?.results.length === 0)
    return (
      // In this case, user most likely does not meet qualifications for any payment options
      <Callout
        title={'Payment Options Are Not Available Right Now'}
        desc={'Please contact us directly to explore your payment options'}
      />
    );

  if (dealIsLoading || deskIsLoading || isSubmitting || programSummaryIsLoading) {
    return (
      <div>
        <div className={`matrix ${deal?.isDeskingLocked ? ' is-locked' : ''}`}>
          <div className="matrix-content">
            <MatrixLoadingItem />
            <MatrixLoadingItem />
            <MatrixLoadingItem />
            <MatrixLoadingItem />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={renderClass}>
        <div className="matrix-content">
          {desk.results.map((v, index) => {
            return v ? (
              <MatrixItem
                control={form.control}
                key={index}
                value={index}
                name="currentCell"
                paymentRange={desk.paymentRange}
                // For now, whole numbers only
                payment={Math.floor(v.payment)}
                termType={desk.termType}
                subtitle={v.term + ' months'}
                // Money factor is rate times 2400
                description={
                  v.isMoneyFactor
                    ? (v.rate * 2400).toLocaleString('en-US', { maximumFractionDigits: 2 }) +
                      '% APR'
                    : v.rate.toLocaleString('en-US', { maximumFractionDigits: 2 }) + '% APR'
                }
                isDeskingLocked={deal?.isDeskingLocked}
                paymentMethod={formPaymentMethod}
              />
            ) : (
              // Return Contact Us item if no term results
              <MatrixContactItem
                key={index}
                subtitle={12 * (index + 2) + ' months'}
                description={'No term results'}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
