import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons';
import { allowedImageExtensions, uploadEnum } from '../../constants/files';
import { Selector } from '../selectors/Selector';
import { getFormattedDate } from 'src/constants/dates';
import { Colors } from 'src/types/colors';
import { useAuth } from '../context/AuthProvider';
import { LoginState } from '../authentication/LoginForm';
import { FullBlockRoute } from 'src/types/blocks';

interface FileManageCardProps {
  id: string;
  imgSrc: string;
  tag: string;
  handleDelete: (id: string, imgSrc?: string) => Promise<void>;
  name?: string;
  updatedAt?: string;
}

export default function FileManageCard({
  id,
  imgSrc,
  tag,
  handleDelete,
  name,
  updatedAt,
}: FileManageCardProps) {
  const jsDate = updatedAt ? new Date(updatedAt) : undefined;
  const formattedDate = jsDate ? getFormattedDate(jsDate) : undefined;
  const { token } = useAuth();
  const lastDotIndex = imgSrc.lastIndexOf('.');
  const lastQueryIndex = imgSrc.lastIndexOf('?');
  const extensionEndIndex = lastQueryIndex === -1 ? imgSrc.length : lastQueryIndex;
  const fileType = imgSrc.substring(lastDotIndex, extensionEndIndex);
  const hasImgThumbnail = allowedImageExtensions.includes(fileType);

  return (
    <Selector
      type={token ? 'div' : 'link'}
      to={token ? `${FullBlockRoute.Documents}${tag}` : '/login'}
      title={name}
      subtitle={jsDate === undefined ? undefined : formattedDate}
      thumbnail={
        token
          ? {
              icon: imgSrc ? undefined : faCloudUpload,
              element: hasImgThumbnail ? (
                <img className="selector--img" src={imgSrc} alt="" />
              ) : null,
              isWide: true,
            }
          : undefined
      }
      label={uploadEnum[tag].label}
      labelColor={Colors.Blue2}
      linkState={LoginState.Verify}
      menuItems={[
        {
          icon: faMinusCircle,
          text: 'Remove File',
          onClick: async () => {
            handleDelete(id, imgSrc);
          },
          confirmModal: {
            title: 'Are you sure?',
            body: `You won't be able to recover this file.`,
          },
        },
      ]}
    />
  );
}
