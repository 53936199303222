import { createRoot, Root } from 'react-dom/client';
import { AppointmentPayload } from 'src/types/appointment';
import { ChatOptions } from 'src/types/chatOptions';
import { CreditApplicationSubmitBlock } from 'src/types/creditapplication-block';
import { CustomerFormattedBlock, GeneralLeadFormattedBlock } from 'src/types/lead';
import { ReferenceFormDefaultValues } from 'src/types/reference-block';
import { TradeinFormattedBlock } from 'src/types/tradein-block';

export type SelectOption = { label: string; value: string; isDefault?: boolean };
export type SelectOptions = SelectOption[];
export type WidgetSuccessMessage = 'message' | string | null; // Deprecated
export type WidgetSuccessConfirmation = { toast?: string; title?: string; description?: string };
export enum WidgetHostMessageType {
  Submit = 'submit',
  Redirect = 'redirect',
  Authentication = 'authentication',
  Modal = 'modal',
}
// Breaking change for Website if this enum is changed
export enum WidgetForm {
  Retailing = 'retailing', // The default experience for a session customer
  BasicInfo = 'basic-info',
  VehicleInquiry = 'vehicle',
  General = 'general',
  Preapproval = 'preapproval',
  Service = 'service',
  Tradein = 'trade-in',
  TestDrive = 'testdrive',
  Appointment = 'appointment',
  CreditApplication = 'payment-method',
  PaymentOptions = 'payment-options',
  //Not really a form but used for navigation when retailing widget is opened
  ThankYou = 'thank-you',
  Chat = 'chat',
}
export interface WidgetVehicle {
  img: string;
  year: number;
  make: string;
  model: string;
  trim: string;
  price: number;
  vin?: string;
  dealershipName?: string;
}

export interface WidgetThankYouCTA {
  title?: string;
  route?: string;
  isHidden?: boolean;
  isGlobal?: boolean; // Applies to authorized users as well
}

export interface WidgetThankYouConfig {
  [key: string]: { title?: string; route?: string; isHidden?: boolean };
}

// parsed from API and all strings if they exist
// used for login widget config / thank you page
export interface WidgetTradeinValuation {
  averageTrade?: string | null | undefined;
  color?: string | null | undefined;
  condition?: string | null | undefined;
  make?: string | null | undefined;
  maxTrade?: string | null | undefined;
  mileage?: string | null | undefined;
  minTrade?: string | null | undefined;
  model?: string | null | undefined;
  trim?: string | null | undefined;
  body?: string | null | undefined;
  year?: string | null | undefined;
  vin?: string | null | undefined;
}

export interface WidgetConfigBase {
  container: HTMLElement;
  success?: WidgetSuccessMessage;
  confirmation?: WidgetSuccessConfirmation;
  name?: string | null;
  title?: string | null;
  body?: string | null;
  message?: string | null;
  form: WidgetForm;
  chatOptions?: ChatOptions;
  display?: { isModal?: boolean; isClosedOnInit?: boolean; zIndex?: number };
}

export type InitSpaceWidgetParams<WidgetConfig extends WidgetConfigBase> = WidgetConfig & {
  hostGlobal: typeof globalThis;
  widgetDomain: string;
};

interface UnauthorizedBlockSubmit<T> {
  blocks: T[];
  customer: CustomerFormattedBlock;
  location: string;
  dealerId?: string;
  references?: ReferenceFormDefaultValues[];
}

interface UnauthorizedLeadBlockSubmit {
  blocks: GeneralLeadFormattedBlock[];
  location: string;
  zip?: string | null; // Preapproval Zip
}

export type UnauthorizedGeneralLeadBlockSubmit = UnauthorizedLeadBlockSubmit;
export type UnauthorizedTradeinBlockSubmit = UnauthorizedBlockSubmit<TradeinFormattedBlock>;
export type UnauthorizedAppointmentBlockSubmit = UnauthorizedBlockSubmit<AppointmentPayload>;
export type UnauthorizedCreditAppBlockSubmit =
  UnauthorizedBlockSubmit<CreditApplicationSubmitBlock>;

export interface UnauthorizedGeneralLeadPayload {
  blocks: GeneralLeadFormattedBlock[];
  location: string;
  vin: string | null;
  zip: string | null;
}

export interface OnDestroyFn {
  (): void;
}

export interface OnMessageFn {
  (message: { type: string; payload: any }): void;
}

export type RenderWidgetParams<WidgetConfig extends WidgetConfigBase> = Omit<
  InitSpaceWidgetParams<WidgetConfig>,
  'container'
> & {
  hostMessage: OnMessageFn;
  scrollToElement?: (id: string) => void;
};

const GOOGLE_FONTS_LINK =
  'https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap';

export abstract class SpaceWidget<WidgetConfig extends WidgetConfigBase> {
  /**
   * Host site global object
   */
  private readonly _hostGlobal: typeof globalThis;

  private readonly _shadowRoot: ShadowRoot;

  private readonly _innerContainer: HTMLDivElement;

  private _googleFontsLink!: HTMLLinkElement;

  protected _initialConfig: RenderWidgetParams<WidgetConfig>;

  /**
   * TODO: Remove when the toaster issue is resolved
   */
  private __toastStylesObserver!: MutationObserver;

  protected _reactRoot: Root | null = null;

  private _onMessage?: OnMessageFn;

  set onMessage(value: OnMessageFn) {
    this._onMessage = value;
  }

  protected hostMessage: OnMessageFn = message => {
    if (typeof this._onMessage === 'function') {
      this._onMessage(message);
    }
  };

  protected scrollToElement = (id: string) => {
    const scrollEl = this._shadowRoot.getElementById(id);
    if (scrollEl) {
      scrollEl.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  };

  constructor(
    config: InitSpaceWidgetParams<WidgetConfig>,
    private readonly onDestroy?: OnDestroyFn
  ) {
    this._hostGlobal = config.hostGlobal;
    this._shadowRoot =
      config.container.shadowRoot || config.container.attachShadow({ mode: 'open' });

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `${config.widgetDomain}/index.css`;
    this._shadowRoot.appendChild(link);
    this._initGoogleFonts();

    this._innerContainer = document.createElement('div');
    this._innerContainer.classList.add('space-widget');

    if (config.form === WidgetForm.Chat) {
      this._innerContainer.classList.add('is-chat');

      if (config.chatOptions?.trigger?.isOpen) {
        this._innerContainer.dataset.state = 'open';
      } else {
        this._innerContainer.dataset.state = 'close';
      }
    }

    if (config.display?.isModal) {
      this._innerContainer.classList.add('is-internal-modal');
    }

    if (config.display?.isModal && !config.display?.isClosedOnInit) {
      this._hostGlobal.document.body.style.overflow = 'hidden';
      this._innerContainer.dataset.modalState = 'open';
    } else {
      this._innerContainer.dataset.modalState = 'close';
    }

    this._innerContainer.id = 'space-widget';
    // add click event to close the modal
    this._innerContainer.addEventListener('click', e => {
      if (e.target === e.currentTarget) {
        if (this._innerContainer.dataset.modalState === 'open') {
          this._innerContainer.dataset.modalState = 'close';
          this._hostGlobal.document.body.style.overflow = 'auto';
          // For now, always reset widget on modal close
          this.reset();
        }
      }
    });

    // Store the initial configuration for reset
    this._initialConfig = {
      ...config,
      hostMessage: this.hostMessage,
    };
    this._shadowRoot.appendChild(this._innerContainer);

    this.__pullReactHotToastIntoShadowRoot();

    this._reactRoot = createRoot(this._innerContainer);
  }

  protected abstract _renderWidget(params: RenderWidgetParams<WidgetConfig>): void;

  destroy() {
    this.__toastStylesObserver.disconnect();

    if (this._reactRoot) {
      this._reactRoot.unmount();
      this._reactRoot = null;
    }
    this._shadowRoot.innerHTML = '';
    this._hostGlobal.document.head.removeChild(this._googleFontsLink);

    if (typeof this.onDestroy === 'function') {
      this.onDestroy();
    }
  }

  reset() {
    if (this._reactRoot) {
      // Unmount the current React app
      this._reactRoot.unmount();

      // Optionally clear the inner container's HTML to ensure a clean slate
      this._innerContainer.innerHTML = '';

      // Create a new root and render the widget again with the initial configuration
      this._reactRoot = createRoot(this._innerContainer);

      // Re-render the widget with its initial configuration
      // The configuration should be stored or passed into this method.
      this._renderWidget(
        this._initialConfig // Add this if you're storing the initial config
      );
    }
  }

  resize(state: 'open' | 'close', height?: string, width?: string) {
    this._innerContainer.dataset.state = state;
    this._innerContainer.dataset.modalState = state;

    if (height) {
      this._innerContainer.style.height = height;
    }

    if (width) {
      this._innerContainer.style.width = width;
      this._innerContainer.style.maxWidth = width;
    }
  }

  // TODO: Remove this hack once PR is closed, pass the shadowRoot into the toast
  // https://github.com/timolins/react-hot-toast/issues/139
  private __pullReactHotToastIntoShadowRoot() {
    const toastStyles = document.getElementById('_goober');
    const toastStylesCopy = document.createElement('style');

    this.__toastStylesObserver = new MutationObserver(mutationList => {
      if (Array.isArray(mutationList) && mutationList.length && toastStyles) {
        toastStylesCopy.innerHTML = toastStyles.innerHTML;
      }
    });

    if (toastStyles) {
      toastStylesCopy.innerHTML = toastStyles.innerHTML;
      this._shadowRoot.appendChild(toastStylesCopy);
      this.__toastStylesObserver.observe(toastStyles, { subtree: true, characterData: true });
    }
  }

  private _initGoogleFonts() {
    // should be added to the host DOM
    // https://bugs.chromium.org/p/chromium/issues/detail?id=336876
    this._googleFontsLink = document.createElement('link');
    this._googleFontsLink.rel = 'stylesheet';
    this._googleFontsLink.href = GOOGLE_FONTS_LINK;
    this._hostGlobal.document.head.appendChild(this._googleFontsLink);
  }
}
