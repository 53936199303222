import { useDesk } from 'src/fetches/useSWRFetch';
import './ComplexLabel.css';
import { useDeals } from '../context/DealsProvider';
import { useMonthlyPayment } from 'src/helpers/useMonthlyPayment';

export default function ComplexLabel() {
  const { monthlyPayment, monthlyPaymentIsLoading } = useMonthlyPayment();
  const { activeDealId, deal } = useDeals();
  const { desk, deskIsLoading } = useDesk(activeDealId, 'desk', deal?.vin);

  if (monthlyPaymentIsLoading || !monthlyPayment || !desk || deskIsLoading) {
    return null;
  }

  return (
    <div className="complex-label mod-text-align-right">
      <div className="complex-label--title">{monthlyPayment.payment}</div>
      <div className="complex-label--body">{monthlyPayment.termNumber}</div>
    </div>
  );
}
