import { useCallback, useContext } from 'react';
import 'react-day-picker/dist/style.css';
import { useConfig } from '../components/context/config';
import {
  AppointmentAndCustomer,
  AppointmentPayload,
  formatAppointmentBlock,
  formatAppointmentTime,
} from '../types/appointment';
import { AdditionalField, returnAdditionalFieldValues } from '../widgets/AdditionalField';
import { CustomerFormattedBlock, formatCustomerAddonBlock } from '../types/lead';
import { AppointmentBuilder } from 'src/pages/deal/appointments/AppointmentBuilder';
import toast from 'react-hot-toast';
import { WidgetForm, WidgetVehicle } from 'src/widgets/SpaceWidget';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { BlockRoute } from 'src/types/blocks';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { UnauthorizedSubmitCallback, useHandleSuccess } from 'src/handlers/useHandleFormSuccess';
import { useDeals } from 'src/components/context/DealsProvider';
import { useSchedule } from 'src/components/context/ScheduleProvider';
import { useApiFetch } from 'src/fetches/useApiFetch';
import PageLoader from 'src/components/PageLoader';

export function UnauthorizedAppointmentForm({
  form,
  vehicle,
  additionalFields,
  title,
  body,
  options,
  name,
  hideBackButton,
  assumeCustomer = false,
  isVehiclePickup = false,
}: {
  form: WidgetForm;
  vehicle: WidgetVehicle | { vin: string | null } | null;
  additionalFields: AdditionalField[];
  title: any;
  body: any;
  options?: any;
  name?: any;
  hideBackButton?: boolean;
  assumeCustomer?: boolean;
  isVehiclePickup?: boolean;
}) {
  const config = useConfig()!;
  const { deal } = useDeals();
  const apiFetch = useApiFetch();
  const { navigateNextBlock, navigatePrevBlock } = useContext(NavigationContext);
  const handleSuccess: UnauthorizedSubmitCallback = useHandleSuccess(
    config.hostMessage!,
    form,
    () => navigateNextBlock()
  );

  const { sessionCustomer, setSessionAppointment, setSessionCustomer, setSessionVehicle } =
    useSessionContext();
  const { dealerTimezone } = useSchedule();
  // const { searchVehicle } = useVehicleVinSearch(vehicle?.vin);

  const handleFormSubmit = useCallback(
    async (data: AppointmentAndCustomer) => {
      const commentSections = returnAdditionalFieldValues(additionalFields, data);

      const utcDateString = formatAppointmentTime(
        data.time,
        data.calendarAppointmentDate,
        dealerTimezone
      );

      const customer: CustomerFormattedBlock = formatCustomerAddonBlock(data);

      const block: AppointmentPayload = formatAppointmentBlock(
        data,
        utcDateString,
        commentSections
      );

      try {
        const {
          dealId,
          blocks: responseBlocks,
          customerId,
        } = await apiFetch('unauthorized/appointment', {
          method: 'POST',
          body: JSON.stringify({
            customer,
            blocks: [block],
            location: data.location,
            vin: data.vin || null,
          }),
        });
        setSessionAppointment(responseBlocks[0]);
        setSessionCustomer(customer);
        setSessionVehicle(vehicle);

        await handleSuccess(customer.email, dealId, customerId, responseBlocks[0].appointmentAt);
      } catch (error: any) {
        toast.error(error.message);
        throw error.message;
      }
    },
    [
      handleSuccess,
      apiFetch,
      setSessionAppointment,
      setSessionCustomer,
      setSessionVehicle,
      additionalFields,
      vehicle,
      dealerTimezone,
    ]
  );

  // Wait for vehicle's location to load
  if (!deal && vehicle) {
    return <PageLoader />;
  }

  return (
    <AppointmentBuilder
      configuration={{
        appointment: undefined,
        requirePhone: config.forms?.lead?.requirePhone ?? true,
        handleFormSubmit,
        handleFormCancel: async () => navigatePrevBlock(BlockRoute.Appointments),
        hideBackButton,
        additionalFields,
        vehicle: vehicle?.vin ? vehicle : null,
        isVehicleReadOnly: true,
        title,
        body,
        appointmentTypeOptions: options ?? [],
        name,
        submitText: 'Submit',
        customerFieldsAssumed: assumeCustomer && sessionCustomer?.email ? true : false,
        isVehiclePickup,
        location: deal?.dealershipName,
      }}
    />
  );
}
