import { useMemo, useCallback, useState, useEffect } from 'react';
import { faCloudUpload, faEllipsisH, faMobile, faPlus } from '@fortawesome/pro-regular-svg-icons';
import FileUploadError from './FileUploadError';
import FileManageCard from '../cards/FileManageCard';
import { useDropzone } from 'react-dropzone';
import { useConfig } from '../context/config';
import ConfirmModal from '../floating/dialog/ConfirmModal';
import { Dialog } from '../floating/dialog/Dialog';
import './FileUpload.css';
import { allowedUploadExtensions } from '../../constants/files';
import { DocumentBlock, DocumentTag } from 'src/types/document-block';
import { Colors } from 'src/types/colors';
import { useApiFetch } from 'src/fetches/useApiFetch';
import { Selector } from '../selectors/Selector';
import { ComponentState } from 'src/types/component';

export interface ServerFile {
  key: string;
  url: string;
  name: string;
  tag?: string;
  updatedAt: string;
}

export interface LocalFile {
  key: string;
  url: string;
  updatedAt?: string;
  id: string;
  name?: string;
}

interface FileUploadProps {
  files: LocalFile[] | DocumentBlock[] | null;
  handleSubmit: (data: DocumentBlock[]) => void;
  tag: DocumentTag;
  currentPhotos: LocalFile[];
  removeFile: (src: string, key: string) => Promise<void>;
}

export default function FileUpload({
  files,
  handleSubmit,
  tag,
  currentPhotos,
  removeFile,
}: FileUploadProps) {
  const config = useConfig()!;
  const apiFetch = useApiFetch();
  const [confirmModal, setConfirmModal] = useState(false);
  const [, setProgress] = useState(0);
  const [placeholderFiles, setPlaceholderFiles] = useState<File[]>([]);

  async function getSignedUrl(filename: string) {
    const obj = await apiFetch(`unauthorized/signed-url?name=${filename}`, {
      headers: {
        origin: `https://${config.websiteDomain}`,
      },
    });

    return obj;
  }

  async function uploadFile(file: File, signedData: any) {
    const res = await fetch(signedData.signedUrl, {
      method: 'PUT',
      // @ts-ignore
      body: file,
      headers: {
        'Content-Length': file.size.toString(),
        'Content-Type': signedData.contentType,
      },
    }).then(res => res.text());

    return res;
  }

  // eslint-disable-next-line
  async function submitDocuments(files: File[]) {
    const fileNames = files.map(file => file.name);
    // Generate signed URLs for all files in parallel
    const signedDataArray = await Promise.all(fileNames.map(filename => getSignedUrl(filename)));

    // Upload all files using the signed URLs
    await Promise.all(
      signedDataArray.map((signedData, index) => uploadFile(files[index], signedData))
    );

    const blocks = fileNames.map((filename, index) => ({
      tag,
      url: signedDataArray[index].url,
      name: filename,
    }));

    handleSubmit(blocks as DocumentBlock[]);
  }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setProgress(1);
      setPlaceholderFiles(acceptedFiles);

      // Abort if there were no files selected
      if (!acceptedFiles) return;

      submitDocuments(acceptedFiles);
    },
    [submitDocuments]
  );

  const onDropRejected = useCallback(
    rejFiles => {
      rejFiles.forEach(x => (x.tag = tag));
      setConfirmModal(true);
    },
    [tag]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
    fileRejections,
  } = useDropzone({
    accept: allowedUploadExtensions,
    onDrop,
    onDropRejected,
  });

  const style = useMemo(() => {
    if (isDragActive || isFocused) {
      return 'form--upload--dragzone is-active';
    }

    if (isDragAccept) {
      return 'form--upload--dragzone is-accepted';
    }

    if (isDragReject) {
      return 'form--upload--dragzone is-error';
    }

    return 'form--upload--dragzone ';
  }, [isDragActive, isDragReject, isDragAccept, isFocused]);

  const serverFiles = files
    ? files.map((file: LocalFile | DocumentBlock) => (
        <FileManageCard
          key={file.url}
          id={file.id}
          imgSrc={file.url} // image url
          tag={tag} // trade in
          updatedAt={file.updatedAt}
          name={file.name}
          handleDelete={removeFile} // remove file from server
        />
      ))
    : null;

  const currentFiles = currentPhotos.length
    ? currentPhotos.map((file, index) => (
        <FileManageCard
          key={file.url}
          id={file.id}
          imgSrc={file.url}
          tag={tag}
          updatedAt={file.updatedAt}
          name={file.name || `File ${index + 1}`}
          handleDelete={removeFile}
        />
      ))
    : null;

  const placeholders = placeholderFiles.length
    ? placeholderFiles.map((file, index) => (
        <Selector
          key={index}
          type="div"
          state={[ComponentState.ReadOnly]}
          thumbnail={{ icon: faCloudUpload }}
          title={file[index]?.name || 'Uploading...'}
          labelColor={Colors.Gray6}
          btnIcon={faEllipsisH}
        />
      ))
    : null;

  useEffect(() => {
    setPlaceholderFiles([]);
  }, [currentPhotos]);

  return (
    <section className="form--upload--holder">
      <div {...getRootProps()} className={style}>
        <input {...getInputProps()} />
        <div className="form--upload--text u-hide-mobile-and-ipad-block">
          Drag and drop files or <span className="u-color-accent">browse</span>
          <span className="form--upload--desc">(max size 4.5 MB)</span>
        </div>
        <div className="form--upload--mobile-selector">
          <Selector
            type="button"
            title="Upload from Device"
            thumbnail={{ icon: faMobile }}
            btnIcon={faPlus}
          />
        </div>
      </div>

      <div className="form--upload--file-grid">
        {placeholders}
        {currentFiles}
        {serverFiles}
        <Dialog open={confirmModal} onOpenChange={setConfirmModal}>
          <ConfirmModal
            headerText="Invalid File"
            isSubmitting={false}
            confirmAction={() => {
              setConfirmModal(false);
            }}
            bodyText={
              <>
                {fileRejections.map(({ file, errors }, index) => (
                  <FileUploadError key={index} file={file} errors={errors} />
                ))}
              </>
            }
          />
        </Dialog>
      </div>
    </section>
  );
}
