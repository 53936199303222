import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'src/components/context/AuthProvider';
import { useDeals } from 'src/components/context/DealsProvider';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useDesk, useProgramSummary } from 'src/fetches/useSWRFetch';
import { PaymentType } from 'src/types/deal';
import { DeskingPayload } from 'src/types/desking';

export const useHandleDeskSubmit = () => {
  const { token } = useAuth();
  const { activeDealId, deal, dealMutate } = useDeals();
  const { desk, deskUpsert } = useDesk(activeDealId, 'desk', deal.vin);
  const { setSessionPaymentType } = useSessionContext();
  const { programSummaryMutate } = useProgramSummary(activeDealId, !desk);

  return useCallback(
    async data => {
      const formattedData: DeskingPayload = {
        creditScore: data.creditScore ?? 0,
        currentCell: Number(data.currentCell) as 0 | 1 | 2 | 3,
        downPayment: data.downPayment[0] ?? 0,
        paymentMethod: data.paymentMethod as PaymentType,
        zip: data.zip ?? '',
      };

      try {
        const desk = await deskUpsert(formattedData);
        await programSummaryMutate();
        await dealMutate();
        if (!token) {
          setSessionPaymentType(data.paymentMethod as PaymentType);
        }
        return {
          paymentMethod: desk.request.paymentMethod,
          zip: desk.request.zip.toString(),
          currentCell: desk.request.currentCell,
          creditScore: desk.request.creditScore,
          downPayment: [desk.request.downPayment],
          monthlyPaymentTarget: '',
        };
      } catch (error: any) {
        toast.error(error.message);
        return {};
      }
    },
    [deskUpsert, programSummaryMutate, setSessionPaymentType, token, dealMutate]
  );
};
