import { UseFormReturn, useWatch } from 'react-hook-form';
import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { isValidDateObject, siteDateFormat } from '../../constants/validation';
import { Select } from '../controls/Select';
import { Matcher } from 'react-day-picker';
import { DateDialog } from './DateDialog';
import './AppointmentPicker.css';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { useSchedule } from '../context/ScheduleProvider';
import { convertHour, getEditFriendlyTime } from 'src/constants/dates';

interface AppointmentPickerProps {
  form: UseFormReturn<any>;
  initialDate: Date;
}

export const AppointmentPicker = ({ form, initialDate }: AppointmentPickerProps) => {
  const { dropdownOptions } = useSchedule();
  const [open, setOpen] = useState(false);
  const inputAppointmentDate = useWatch({
    control: form.control,
    name: 'inputAppointmentDate',
  });

  const calendarAppointmentDate = useWatch({
    control: form.control,
    name: 'calendarAppointmentDate',
  });

  const selectTime = useWatch({
    control: form.control,
    name: 'time',
  });

  const isTodaySelected = useMemo(() => {
    return (
      format(new Date(calendarAppointmentDate), siteDateFormat) ===
      format(new Date(), siteDateFormat)
    );
  }, [calendarAppointmentDate]);

  const standardTimeOptions =
    dropdownOptions.timeOptions[format(new Date(calendarAppointmentDate), 'EEEE').toLowerCase()];

  const todaysTimeArray = useMemo(() => {
    let currentHour = new Date().getHours();
    let currentMinutes = new Date().getMinutes();

    return standardTimeOptions.map(option => {
      const hours = parseInt(option.value.split(':')[0]);
      const minutes = parseInt(option.value.split(':')[1]);

      return {
        ...option,
        disabled:
          hours < currentHour ||
          (hours === currentHour && minutes < currentMinutes) ||
          option.disabled,
      };
    });
  }, [standardTimeOptions]);

  const disabledDays: Matcher[] = useMemo(() => {
    let disabledDays: Matcher[] = [];

    disabledDays.push({ dayOfWeek: dropdownOptions.closedDays });

    if (todaysTimeArray.every(option => option.disabled)) {
      disabledDays.push(new Date());
    }

    return disabledDays;
  }, [dropdownOptions.closedDays, todaysTimeArray]);

  const handleReselectTime = (isTodaySelected: boolean, selectedDay: Date) => {
    const selectedDateArray =
      dropdownOptions.timeOptions[format(new Date(selectedDay), 'EEEE').toLowerCase()];
    if (isTodaySelected) {
      form.setValue('time', todaysTimeArray.find(option => !option.disabled)?.value || null);
    } else if (!selectedDateArray.find(option => option.value === selectTime)) {
      form.setValue('time', selectedDateArray[0]?.value || null);
    }
  };

  const handleBlur = (name: string) => {
    if (name === 'inputAppointmentDate') {
      if (
        !isValidDateObject(new Date(inputAppointmentDate)) ||
        dropdownOptions.closedDays.includes(new Date(inputAppointmentDate).getDay())
      ) {
        // default to whatever the calendar shows
        form.setValue(
          'inputAppointmentDate',
          format(new Date(calendarAppointmentDate), siteDateFormat) || null
        );
      }
    } // Currently we don't need to handle blur for the calendar
  };

  const handleDayClick = (selectedDay: Date) => {
    // ensure valid date is being passed
    if (selectedDay) {
      form.setValue('calendarAppointmentDate', selectedDay || null);
      form.setValue('inputAppointmentDate', format(new Date(selectedDay), siteDateFormat) || null);

      // If the just picked date is today, set the time to the next available time
      handleReselectTime(
        format(new Date(selectedDay), siteDateFormat) === format(new Date(), siteDateFormat),
        selectedDay
      );

      setOpen(false);
    }
  };

  const updateDatepickerInput = (updatedFormValue: string) => {
    const newDate = new Date(updatedFormValue);

    if (isValidDateObject(newDate) && !dropdownOptions.closedDays.includes(newDate.getDay())) {
      if (newDate > new Date()) {
        form.setValue('calendarAppointmentDate', newDate || null);
        form.setValue('inputAppointmentDate', updatedFormValue);
        handleReselectTime(
          format(newDate, siteDateFormat) === format(new Date(), siteDateFormat),
          newDate
        );
      }
    } else {
      form.setValue('inputAppointmentDate', updatedFormValue);
    }
  };

  const placeholder = useMemo(() => {
    // Returns a placeholder time that looks like a real selected time for an out of hours appt
    const aptDate = getEditFriendlyTime(calendarAppointmentDate).substring(0, 5);
    const aptDateSplit = aptDate.split(':');
    const hour = convertHour(parseInt(aptDateSplit[0]), true);
    const reformattedTime = `${hour}:${aptDateSplit[1]} ${
      parseInt(aptDateSplit[0]) > 11 ? 'pm' : 'am'
    }`;

    return reformattedTime;
  }, [calendarAppointmentDate]);

  return (
    <div className="u-display-grid grid--50 grid--gap-8">
      <DateDialog
        open={open}
        setOpen={setOpen}
        updateDatepickerInput={updateDatepickerInput}
        initialDate={initialDate}
        disabledDays={disabledDays}
        handleDayClick={handleDayClick}
        inputAppointmentDate={inputAppointmentDate}
        calendarAppointmentDate={calendarAppointmentDate}
        handleBlur={handleBlur}
      />
      <div className="time-selector">
        <div className="time-selector--time">
          <Select
            control={form.control}
            name="time"
            placeholder={placeholder}
            icon={faClock}
            selectOptions={isTodaySelected ? todaysTimeArray : standardTimeOptions}
          />
        </div>
      </div>
    </div>
  );
};
