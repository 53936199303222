import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/components/context/AuthProvider';
import { useConfig } from 'src/components/context/config';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { WebsiteRoute } from 'src/external/WebsiteRoutes';
import { BlockRoute, RouteItem } from 'src/types/blocks';
import { WidgetHostMessageType } from 'src/widgets/SpaceWidget';

export function getFallbackRoute(routes: any, blockRoute: BlockRoute, defaultRoute: WebsiteRoute) {
  return routes && routes[blockRoute]?.route ? routes[blockRoute].route : defaultRoute;
}

export const useHandleRedirect = () => {
  const config = useConfig()!;
  const { token } = useAuth();
  const navigate = useNavigate();
  const { sessionVehicleDealString } = useSessionContext();
  const isRetailingEnabled = config.packages?.retailing;

  return useCallback(
    async (item: RouteItem) => {
      if (isRetailingEnabled) {
        if (token) {
          // Send all authorized users to standalone portal for now
          const route = config.redirectUrl + item.route + sessionVehicleDealString;
          window.open(route, '_blank');
          config.hostMessage!({
            type: WidgetHostMessageType.Redirect,
            payload: {
              isLoggedIn: token ? true : false,
              route,
            },
          });
        } else {
          navigate(item.route);
        }
      } else {
        const route = getFallbackRoute(config.routes, item.name, item.websiteRoute as WebsiteRoute);
        window.open(route, '_blank');
        config.hostMessage!({
          type: WidgetHostMessageType.Redirect,
          payload: {
            isLoggedIn: token ? true : false,
            route,
          },
        });
      }
    },
    [config, sessionVehicleDealString, token, isRetailingEnabled, navigate]
  );
};
