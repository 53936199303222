import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import { useConfig } from 'src/components/context/config';
import { Selector } from 'src/components/selectors/Selector';
import { SelectorAccordionSearch } from 'src/components/selectors/SelectorAccordionSearch';
import { VehicleSelectionWithVinSearch } from 'src/components/steps/VehicleSelection';
import { WebsiteRoute } from 'src/external/WebsiteRoutes';

export function WebsiteVehicleBrowser() {
  const config = useConfig()!;
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false);
  const [analyticsVehicles, setAnalyticsVehicles] = useState<any>([]);

  useEffect(() => {
    const checkScriptLoaded = () => {
      if (
        (window.parent as any) &&
        typeof (window.parent as any).SpaceWebAnalytics === 'function'
      ) {
        clearInterval(scriptCheckInterval);

        (window.parent as any).SpaceWebAnalytics.getViews().then(
          (views: { vin: string; date: string }[]) => {
            const combinedArray: { vin: string }[] = [];
            const visitedVins = localStorage.getItem('visitedVins');
            const parsedVisitedVins = visitedVins ? JSON.parse(visitedVins) : [];

            if (views?.length) {
              views.forEach(view => {
                if (!combinedArray.some(item => item.vin === view.vin)) {
                  combinedArray.push({ vin: view.vin });
                }
              });
            }

            parsedVisitedVins?.forEach(vin => {
              if (!combinedArray.some(item => item.vin === vin)) {
                combinedArray.push({ vin });
              }
            });

            setAnalyticsVehicles(combinedArray.slice(0, 8));
            setAnalyticsLoaded(true);
          }
        );
      }
    };

    const scriptCheckInterval = setInterval(checkScriptLoaded, 500); // Check every 500ms

    return () => clearInterval(scriptCheckInterval); // Clean up the interval on unmount
  }, []);

  if (!analyticsLoaded) return null;

  if (analyticsLoaded && !analyticsVehicles.length) {
    return (
      <Selector
        type="button"
        btnIcon={faPlus}
        title="Search Inventory"
        subtitle="Find a vehicle to explore payment options."
        handleClick={() =>
          window.open(`https://${config.websiteDomain}${WebsiteRoute.ExploreVehicle}`)
        }
      />
    );
  }

  return (
    <>
      {analyticsVehicles?.map(vehicle => {
        return <VehicleSelectionWithVinSearch key={vehicle.vin} vin={vehicle.vin} />;
      })}
      <SelectorAccordionSearch />
    </>
  );
}
