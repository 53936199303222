import { CalculatorGroup, validationSchema } from '../../../components/steps/CalculatorGroup';
import { useDesk, useDeskUpdatedAt } from 'src/fetches/useSWRFetch';
import { useDeals } from 'src/components/context/DealsProvider';
import PageLoader from 'src/components/PageLoader';
import { useConfig } from 'src/components/context/config';
import { PaymentType, SingleDealResponse, UnauthorizedDealResponse } from 'src/types/deal';
import { useCallback, useContext, useEffect, useState } from 'react';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import FormErrorPage from 'src/components/form/FormShell';
import { FormContentWrapper, FormWrapper, useSpaceForm } from 'src/components/form/Form';
import { DeskingReponse } from 'src/types/desking';
import { VehicleCondition } from 'src/types/vehicle';
import { Verify } from 'src/components/authentication/Verify';
import { useHandleDeskSubmit } from 'src/handlers/useHandleDeskSubmit';
import { FormSkip } from 'src/components/form/FormSkip';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useAuth } from 'src/components/context/AuthProvider';

interface CalculatorBuilderProps {
  activeDealId: string;
  deal: SingleDealResponse | UnauthorizedDealResponse;
  desk: DeskingReponse;
  isSubmitting: boolean;
  zip?: string;
  handleFormSubmit: any;
  sellingPrice: number;
}

export function CalculatorBuilder({
  activeDealId,
  deal,
  desk,
  isSubmitting,
  zip,
  handleFormSubmit,
  sellingPrice,
}: CalculatorBuilderProps) {
  const activeForm = 'paymentOptions';
  const config = useConfig()!;
  const { token } = useAuth();
  const { navigateNextBlock, nextBlockTitle } = useContext(NavigationContext);
  const [submittedData, setSubmittedData] = useState({});

  const { paymentOptionsVisited, setPaymentOptionsVisited } = useSessionContext();
  const paymentMethod =
    desk?.request?.paymentMethod || config.locations[0].deskingConfig?.general.paymentMethod;
  const isUsed = deal?.condition === VehicleCondition.Used;
  const fallbackPaymentObject =
    paymentMethod === PaymentType.Financing
      ? config.locations[0].deskingConfig?.financing
      : config.locations[0].deskingConfig?.leasing;
  const fallbackDownPaymentPercent = isUsed
    ? fallbackPaymentObject?.used.downPaymentPercent
    : fallbackPaymentObject?.new.downPaymentPercent;
  const requestZip = desk.request?.zip && desk.request?.zip !== '0' ? desk.request?.zip : zip;

  const form = useSpaceForm(validationSchema, {
    paymentMethod: paymentMethod,
    creditScore:
      desk.request?.creditScore || config.locations[0].deskingConfig?.general.creditScore || 0,
    // Zip is sometimes the string "null" from server
    zip: requestZip || config.locations[0].deskingConfig?.zip || '',
    currentCell: desk.request?.currentCell || 0,
    downPayment:
      desk.request?.downPayment !== null &&
      desk.request?.downPayment !== undefined &&
      // We are not rendering this component if selling price is null
      desk.request?.downPayment <= (sellingPrice as number)
        ? [desk.request?.downPayment]
        : [(sellingPrice as number) * 0.01 * (fallbackDownPaymentPercent || 0)],
    monthlyPaymentTarget: '',
  });

  const handleNext = async () => {
    const vehicleId = token ? activeDealId : deal?.vin;

    if (paymentOptionsVisited && !paymentOptionsVisited.includes(vehicleId)) {
      setPaymentOptionsVisited([...paymentOptionsVisited, vehicleId]);
    }

    navigateNextBlock();
  };

  useEffect(() => {
    if (form.formState.isSubmitSuccessful) {
      form.reset({ ...submittedData });
    }
  }, [submittedData, form]);

  return (
    <FormWrapper
      handleFormSubmit={async data => handleFormSubmit(data).then(data => setSubmittedData(data))}
      handleFormCancel={() => {}}
      steps={[activeForm]}
      classNames="mod-overflow"
    >
      {config.isRetailing ? <div></div> : null}
      <FormContentWrapper form={form} id={activeForm}>
        <CalculatorGroup form={form} sellingPrice={sellingPrice} />
      </FormContentWrapper>
      <div className="block-container--overflow--padding">
        <FormSkip
          alternateButtonText={nextBlockTitle}
          isSubmitting={isSubmitting}
          handleSubmit={handleNext}
        />
      </div>
    </FormWrapper>
  );
}

export const Calculator = ({ zip }) => {
  const { token } = useAuth();
  const { activeDealId, deal, dealIsLoading, dealIsMutating } = useDeals();
  const { desk, deskIsLoading, deskIsError, deskIsMutating, deskMutate } = useDesk(
    activeDealId,
    'desk',
    deal.vin
  );
  const { deskUpdatedAt, deskUpdatedAtIsLoading } = useDeskUpdatedAt(activeDealId);
  const handleFormSubmit = useHandleDeskSubmit();
  const [refreshTimestamp, setRefreshTimestamp] = useState<number>();

  const handleRefreshExternalDeskChanges = useCallback(async () => {
    await deskMutate();
    setRefreshTimestamp(Date.now());
  }, [deskMutate]);

  useEffect(() => {
    if (!desk || deskIsLoading || deskUpdatedAtIsLoading || !deskUpdatedAt) return;

    if (desk.updatedAt < deskUpdatedAt) {
      handleRefreshExternalDeskChanges();
    }
  }, [
    deskUpdatedAt,
    desk,
    deskIsLoading,
    deskUpdatedAtIsLoading,
    handleRefreshExternalDeskChanges,
  ]);

  if (deskIsError) {
    // Only happens for unauthorized users
    if (deskIsError?.status === 409) {
      return (
        <Verify
          title="You saved a payment option on this vehicle"
          subtitle="For your security, this information is protected. Please login to view your saved deal."
        />
      );
    }
    return (
      <FormErrorPage
        configuration={{
          title: "Payment Options aren't available here yet.",
          subtitle: 'Love this vehicle? Contact us directly to discuss payment options',
        }}
      />
    );
  }

  if (!desk || deskIsLoading) {
    return <PageLoader />;
  }

  if ((token && !deal?.sellingPrice) || dealIsLoading) {
    return null;
  }

  return (
    <CalculatorBuilder
      key={refreshTimestamp}
      activeDealId={activeDealId}
      deal={deal}
      desk={desk}
      isSubmitting={
        // (!deal.isLeaseEnabled && deal.paymentType === PaymentType.Leasing) ||
        dealIsMutating || deskIsMutating
      }
      zip={zip}
      handleFormSubmit={handleFormSubmit}
      sellingPrice={token ? deal.sellingPrice : (deal.price as number)} // Forcefully set selling price to vehicle price
    />
  );
};
