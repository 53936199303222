import { AdditionalField, returnAdditionalFieldValues } from '../widgets/AdditionalField';
import { useConfig } from '../components/context/config';
import { UnauthorizedGeneralLeadPayload, WidgetForm, WidgetVehicle } from 'src/widgets/SpaceWidget';
import toast from 'react-hot-toast';
import { useCallback, useContext, useEffect } from 'react';
import { CustomerBlockDefaultValues } from 'src/types/customer-block';
import { GeneralLeadFormattedBlock, formatGeneralLeadBlock } from 'src/types/lead';
import { LeadFormBuilder } from 'src/pages/deal/appointments/LeadFormBuilder';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { UnauthorizedSubmitCallback, useHandleSuccess } from 'src/handlers/useHandleFormSuccess';
import { NavigationContext } from 'src/components/context/NavigationProvider';
import { useDeals } from 'src/components/context/DealsProvider';
import { useAuth } from 'src/components/context/AuthProvider';
import { FullBlockRoute } from 'src/types/blocks';
import { useApiFetch } from 'src/fetches/useApiFetch';
import PageLoader from 'src/components/PageLoader';

interface UnauthorizedLeadFormProps {
  form: WidgetForm;
  title: string | null;
  body: string | null;
  additionalFields: AdditionalField[];
  vehicle: WidgetVehicle | { vin: string | null } | null;
  vin: string | null;
  submitText: string;
  requireZip: boolean;
  additionalPayload?: any;
  customNavigationHandle?: any;
}

export function UnauthorizedLeadForm({
  form,
  title,
  body,
  additionalFields,
  vin,
  vehicle,
  submitText,
  requireZip,
  additionalPayload,
  customNavigationHandle,
}: UnauthorizedLeadFormProps) {
  const config = useConfig()!;
  const { deal } = useDeals();
  const { token } = useAuth();
  const apiFetch = useApiFetch();
  const { navigateNextUnfinishedBlock } = useContext(NavigationContext);
  const handleSuccess: UnauthorizedSubmitCallback = useHandleSuccess(
    config.hostMessage!,
    form,
    customNavigationHandle
  );

  const requireLocation =
    config.location !== null &&
    config.locations.length > 1 &&
    (form === WidgetForm.General || form === WidgetForm.Preapproval);

  const {
    setSessionCustomer,
    setSessionVehicle,
    setSessionZip,
    clearSessionVehicle,
    paymentOptionsVisited,
    setPaymentOptionsVisited,
    vinsWithDeals,
    setVinsWithDeals,
  } = useSessionContext();

  // Determine if user already submitted a lead for this vehicle and retailing is enabled
  // const redirectToTabbedPage =
  //   form === WidgetForm.VehicleInquiry &&
  //   vinsWithDeals &&
  //   vinsWithDeals.find(v => v === vin) &&
  //   config.packages?.retailing;
  // Temp fix until we decide how to handle redirects
  const redirectToTabbedPage = false;

  const handleFormSubmit = useCallback(
    async (data: CustomerBlockDefaultValues) => {
      const commentSections = returnAdditionalFieldValues(additionalFields, data);
      const block: GeneralLeadFormattedBlock = formatGeneralLeadBlock(data, commentSections);
      const location =
        data.location === ''
          ? requireLocation
            ? config.locations[0].name // Fallback
            : undefined
          : data.location;
      const zip = data.zip === '' ? null : data.zip;
      try {
        const { dealId, customerId } = await apiFetch('unauthorized/general-lead', {
          method: 'POST',
          body: JSON.stringify({
            blocks: [{ ...block, ...additionalPayload }],
            vin: vin || null,
            location,
            zip: zip ?? null,
          } as UnauthorizedGeneralLeadPayload),
        });
        const { comments, ...blockWithoutComments } = block;
        setSessionCustomer(blockWithoutComments);
        if (vin) {
          setSessionVehicle({ vin });
          // add vin to the vinswithdeals array

          if (vinsWithDeals && !vinsWithDeals.includes(vin)) {
            setVinsWithDeals([...vinsWithDeals, vin]);
          }
        } else {
          clearSessionVehicle();
        }
        if (zip) {
          setSessionZip(zip);
        }

        // Record a payment options visit for this vin
        if (additionalPayload?.creditScore) {
          if (paymentOptionsVisited && !paymentOptionsVisited.includes(vin as string)) {
            setPaymentOptionsVisited([...paymentOptionsVisited, vin as string]);
          }
        }
        await handleSuccess(block.email, dealId, customerId, block);
      } catch (error: any) {
        if (error instanceof Error) {
          toast.error(error.message);
          throw error.message;
        } else {
          toast.error('An error occurred');
          throw Error;
        }
      }
    },
    [
      additionalPayload,
      additionalFields,
      config.locations,
      apiFetch,
      vin,
      setSessionCustomer,
      setSessionVehicle,
      setSessionZip,
      clearSessionVehicle,
      paymentOptionsVisited,
      setPaymentOptionsVisited,
      handleSuccess,
      requireLocation,
      vinsWithDeals,
      setVinsWithDeals,
    ]
  );

  // User opened a vehicle form but they've already submitted a lead for this vehicle. Send them to next unfinished block
  useEffect(() => {
    if (redirectToTabbedPage) {
      navigateNextUnfinishedBlock();
      window.open(config.redirectUrl + FullBlockRoute.PaymentOptions, '_blank');
    }
  }, [redirectToTabbedPage, navigateNextUnfinishedBlock, config]);
  // Wait for vehicle's location to load
  if (redirectToTabbedPage || (!deal && vehicle && !token)) {
    return <PageLoader />;
  }

  return (
    <LeadFormBuilder
      configuration={{
        handleFormSubmit,
        handleFormCancel: async () => {},
        additionalFields,
        vehicle,
        title,
        body,
        hideBackButton: true,
        submitText,
        location: deal?.dealershipName,
        requirePhone: config.forms?.lead?.requirePhone ?? true,
        requireZip,
        requireLocation,
      }}
    />
  );
}
