import { useMemo } from 'react';
import { useDesk, useProgramSummary } from 'src/fetches/useSWRFetch';
import { returnTermTypeString } from 'src/constants/termType';
import { useDeals } from 'src/components/context/DealsProvider';
import { useConfig } from 'src/components/context/config';
import { returnFlooredPrice, returnUserFriendlyPrice } from 'src/constants/price';

export function useMonthlyPayment() {
  const { activeDealId, deal, dealIsLoading, dealIsMutating } = useDeals();
  const { desk, deskIsLoading } = useDesk(activeDealId, 'desk', deal?.vin);
  const {
    programSummary,
    programSummaryIsError,
    programSummaryIsLoading,
    programSummaryIsMutating,
  } = useProgramSummary(activeDealId, !desk);
  const config = useConfig()!;
  const paymentsAreLoading =
    dealIsLoading ||
    dealIsMutating ||
    deskIsLoading ||
    programSummaryIsLoading ||
    programSummaryIsMutating;
  const noDealPayment = !dealIsLoading && !deal;
  const paymentsAreError = !programSummary || !desk || !desk.results || desk.results.length === 0;
  const currentCellNotAvailable = programSummaryIsError && desk?.results;

  const getMonthlyPayment = () => {
    let fullPayment: { payment?: string; termType?: string; termNumber?: string } = {};

    if (paymentsAreLoading || paymentsAreError || noDealPayment || currentCellNotAvailable)
      return undefined;

    const currentPayment = programSummary.payment;
    const termNumber = programSummary.term;
    const isCashDeal = programSummary.term === 1; // 1 month term means cash, updates more in sync than deal.paymentType

    // Format decimal places based on payment type
    fullPayment.payment = isCashDeal
      ? returnUserFriendlyPrice(currentPayment)
      : returnFlooredPrice(currentPayment);

    if (desk.paymentRange) {
      fullPayment.payment += ` - ${returnFlooredPrice(desk.paymentRange + currentPayment)}`;
    }

    // Term type string - only visible on mobile
    if (!isCashDeal) {
      fullPayment.termType = returnTermTypeString(programSummary.termType);
      fullPayment.termNumber = `${termNumber} months`;
    } else {
      fullPayment.termNumber = 'Cash';
    }

    return fullPayment;
  };

  const monthlyPayment = useMemo(getMonthlyPayment, [
    desk,
    programSummary,
    paymentsAreLoading,
    noDealPayment,
    currentCellNotAvailable,
    paymentsAreError,
  ]);

  return {
    monthlyPayment,
    monthlyPaymentIsLoading:
      paymentsAreLoading ||
      paymentsAreError ||
      monthlyPayment === undefined ||
      currentCellNotAvailable,
    monthlyPaymentIsHidden:
      config.forms?.retailing?.hidePayments ||
      monthlyPayment === undefined ||
      currentCellNotAvailable,
  };
}
