import { useMemo } from 'react';
import { faCalendarStar, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { Selector } from '../selectors/Selector';
import { useAppointmentStatus } from '../../helpers/useAppointmentStatus';
import { AppointmentStatus, AppointmentType } from '../../types/appointment';
import { AppointmentBlock } from 'src/types/appointment-block';
import { Colors } from 'src/types/colors';
import { returnVehiclePhoto } from 'src/types/vehicle';
import { VehicleCardImage } from './vehicle/VehicleCardImage';
import { useAuth } from '../context/AuthProvider';
import { LoginState } from '../authentication/LoginForm';
import { useConfig } from '../context/config';
import { returnLabelText } from 'src/constants/appointment';
import { getReadableTime, getShortReadableDate } from 'src/constants/dates';
import { useSchedule } from '../context/ScheduleProvider';
import { utcToZonedTime } from 'date-fns-tz';
import { ComponentState } from 'src/types/component';
import { BlockRoute, FullBlockRoute } from 'src/types/blocks';
import { useHandleRedirect } from 'src/handlers/useHandleRedirect';
import { AppointmentsRoute } from 'src/constants/routes';

interface AppointmentCardProps {
  appointment: AppointmentBlock;
  isTask?: boolean;
}

export default function AppointmentCard({ appointment, isTask = false }: AppointmentCardProps) {
  const config = useConfig()!;
  const { token } = useAuth();
  const { labelColor } = useAppointmentStatus(appointment?.status);
  const { dealerTimezone } = useSchedule();
  const zonedAptDate = utcToZonedTime(appointment.appointmentAt, dealerTimezone);
  const handleRedirect = useHandleRedirect();
  // Check if an appointment was made that is somehow out of hours

  const subtitle = useMemo(() => {
    const date = getShortReadableDate(zonedAptDate);
    const time = getReadableTime(zonedAptDate);

    if (appointment?.location && config.locations.length > 1) {
      return `${date} at ${time} • ${appointment.location}`;
    }
    return `${date} at ${time}`;
  }, [config.locations, appointment, zonedAptDate]);

  const labels = useMemo(() => {
    let labelsArray = [{ label: returnLabelText(appointment.status), labelColor }];

    return labelsArray;
  }, [labelColor, appointment]);

  const getTitle = () => {
    if (appointment?.type === AppointmentType.Sales) return 'Consultation';
    return appointment?.type || getShortReadableDate(zonedAptDate);
  };

  return (
    <Selector
      type={token ? 'link' : 'button'}
      state={
        appointment?.status === AppointmentStatus.Completed ||
        appointment?.status === AppointmentStatus.Missed
          ? [ComponentState.Disabled]
          : undefined
      }
      to={token ? `${FullBlockRoute.Appointments}${appointment.id}` : '/login'}
      title={getTitle()}
      subtitle={subtitle}
      label={labels}
      labelColor={labelColor}
      handleClick={() => {
        if (token) return;
        handleRedirect(AppointmentsRoute);
      }}
      thumbnail={
        isTask
          ? {
              icon: faCheckCircle,
              isWide: false,
            }
          : {
              icon: faCalendarStar,
              iconBackground: Colors.Gray7,
              isWide: true,
              element: appointment?.vehicle?.vin ? (
                <VehicleCardImage
                  imageSrc={returnVehiclePhoto(appointment.vehicle)}
                  className="selector--img"
                />
              ) : null,
            }
      }
      linkState={LoginState.Verify}
      block={BlockRoute.Appointments}
    ></Selector>
  );
}
